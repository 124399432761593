.alice-carousel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  direction: ltr;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
}
.alice-carousel__stage-item * {
  width: 100%;
  aspect-ratio: 1/1;
  line-height: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  border-left: none;
  border-right: 1px solid #707070;
  object-fit: cover;
}
.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}

/* #carousel-img {
  width: 100%;
  filter: grayscale(100);
}

#carousel-img:hover {
  filter: grayscale(0);
  transition: filter 1s ease;
}

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
} */
