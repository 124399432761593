@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

@font-face {
  font-family: DINProRegular;
  src: url(fonts/DINPro/DINPro-Regular.woff2);
}

@font-face {
  font-family: DINProMedium;
  src: url(fonts/DINPro/DINPro-Medium.woff2);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: DINProRegular;
  letter-spacing: 0.04em;
}

::-moz-selection {
  color: #efefef;
  background: #f94819;
}

::selection {
  color: #efefef;
  background: #f94819;
}

.map-container {
  width: 100%;
  height: 100%;
}

@media all and (min-width: 481px) and (max-width: 1024px) {
  #brand1024 {
    display: none;
  }
}
